/**
 * <PRE>
 * System Name : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name : PSScnAcpnUtil.js
 * Description : 전자서명 현장 접수 여부 체크 유틸
 *              AS-IS 파일 PSScnAcpnUtil.js 대응
 *              
 * </PRE>
 */
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil'
import PSCommUtil from '~/src/ui/ps/comm/PSCommUtil'

const PSScnAcpnUtil = {}
PSScnAcpnUtil.scnAcpnPsbData = {}
PSScnAcpnUtil.fnScnAcpnCallBack = function () {}
/************************************************************************************************
 * Function명  : fn_ScnAcpnService
 * 설명        : 현장 접수 가능 여부 체크
 * - 유지면담 대상 FC (IF-PS-0057) : 신계약블러킹종료일이 당일보다 큰 경우 불가처리
 *   - 종료일일이 안된다면 불가
 * - AML대상여부(IF-PS-0069) : 아래 포함 시 불가 처리
 *   2 : PEP   (추가정보 입력하고 결재 필수 : 정치적 노출 유명인사)
 *   3 : 고액자산가 (추가정보 입력하고 결재 필수)
 *   4 : EDD    (추가정보 입력하고 결재 필수)
 *   5 : WHITE LIST  (추가정보입력이나 결재는 없으나 법인/단체의 경우임)
 *   9 : 고위험 H (추가정보 입력 및 결재 필수)
 * ※ 6번 위험평가면제상품 및 9번의 저위험L은 현장접수 가능
 * - AML 친권자 사망수익자 대상추가 처리(사랑On NEXT pjt)
 * - 외국인 신분증 진위확인 대상 : 신분증 진위확인 대상인데, 외국인인 경우 불가 처리
 * 조건 : 월납 25만, 분기납 75만, 연납 300만, 일시납 500만 이상 의 경우
 *
 * - 임직원/FC 본인/가족계약건 : 기준정보의 세대정보(IF-PS-0048)+PQM사용자테이블(임직원/FC여부)+심사정보에서 확인
 *   : PQM 조회까지 신규I/F로 가능한지 확인 중
 * - 실제소유자 상이 계약 (전자서명앱 XML 추출) : ‘N’인 경우 불가 처리
 * - 지정청구대리인신청 (전자서명앱 XML 추출) : ‘Y’인 경우 불가 처리
 * - 적합성 (IF-PS-0067) :  적합성지정장승인여부가 ‘N’인 경우 불가 처리
 * @param pdata
 * @param fnCallBack
 ************************************************************************************************/
PSScnAcpnUtil.fn_ScnAcpnService = function (inputData, fnCallBack) {
  this.vueClone = Object.assign(this, window.vue.getInstance('vue'))
  // 값 초기화
  this.scnAcpnPsbData = {}
  this.scnAcpnPsbData.returnData = {
    scnAcpnPsbYn: ' ', // 현장 접수 가능 여부
    ralOwnerYn: ' ', // 실제소유자 상이 계약
    asgnProxyClamEntYn: ' ', // 지정청구대리인신청
    eusUndwCmpltYmd: '00000000', // EUS계약심사완료일자
    crinfCnsntYn: ' ', // 신용정보제공동의여부
    ukepInvwObjYn: ' ', // 유지면담대상여부
    amlObjYn: ' ', // AML대상여부
    selfContYn: ' ', // 본인계약여부
    bnfrChgYn: ' ', // 수익자 상이 여부
    nobMultpYn: ' ', // 알릴의무사항 2개 이상 시 접수 불가 처리
    gaOnpstYn: ' ', // GA소속여부
    scnAcpnRstcYn: ' ', // 현장접수거부FC조회
    frgnIdcTofCnfObjYn: ' ', // 외국인신분증인증대상여부
    fofOfwrHldofYn: ' ', // 지점 여주임 사번 존재 여부
    diabtWrtUnSlctYn: ' ', // 당뇨작성미선택여부
    coprClctYn: ' ', // 공동구매 여부
    msgCntnt: ' ', // 오류 메세지
    isDcmWcnstOmit: ' ', // 191010 / isDcmWcnstOmit 서면동의생략
    txbkScCd: ' ', // 2020.02.21 세금우대구분코드
    apcPrsonAplScCd: ' ' // 2020.05.31 지정대리청구인신청구분코드
  }
  this.scnAcpnDalwRsnCntnt = '' // 190923, 현장접수불가사유 메시지
  this.fnScnAcpnCallBack = fnCallBack
  this.fn_GetInputData(inputData) // 검색 조건 추출
  //this.fn_ScnAcpnServiceData('txTSSPS13S6')
  this.fn_ScnAcpnServiceData('txTSSPS18S3') // 유지면담 대상 조회부터 시작 변경

}
/************************************************************************************************
 * Function명  : fn_GetInputData
 * 설명        : 조회 Data 추출
 ************************************************************************************************/
PSScnAcpnUtil.fn_GetInputData = function (inputData) {
  this.scnAcpnPsbData.planId = inputData.planId // 설계번호
  this.scnAcpnPsbData.vuchId = inputData.vuchId // 영수증번호
  this.scnAcpnPsbData.elstPartnNo = inputData.eltrnSaveData.elstPartnNo // 파트너ID
  this.scnAcpnPsbData.mnContrNm = inputData.eltrnSaveData.mnContrNm // 고객명 - 내국인인경우 필요
  this.scnAcpnPsbData.contrDwScCd = inputData.eltrnSaveData.contrDwScCd // 거주자구분코드 01:국내/02:국외
  this.scnAcpnPsbData.prcd = inputData.eltrnSaveData.prcd // 상품코드
  this.scnAcpnPsbData.vuchId = inputData.eltrnSaveData.vuchId // 참조번호 KYC 처리내역 확인/변경 -> 영수증번호 보냄
  this.scnAcpnPsbData.trsfHopDdCd = inputData.eltrnSaveData.trsfHopDdCd // 납입주기코드  01-1월납, 02-2월납, 03-3월납, 06-6월납, 12-연납, 99-일시납
  this.scnAcpnPsbData.jobCd = inputData.contInfoData.jobCd // 보험직업코드
  this.scnAcpnPsbData.smtotPrm = inputData.gafData.planInfoData[0].smtotPrm // 보험료
  this.scnAcpnPsbData.payfrqCd = inputData.gafData.planInfoData[0].payfrqCd // 납입기간유형코드 00 일시납, 01 연납, 12 월납, 04 분기납
  this.scnAcpnPsbData.knbScCd = inputData.contInfoData.knbScCd // 국적코드 내국인:ZPER01, 외국인:ZPER02
  this.scnAcpnPsbData.partnerId = inputData.eltrnSaveData.elstPartnNo // BP ID
  this.scnAcpnPsbData.aftRctmHopYn = inputData.eltrnSaveData.aftRctmHopYn // 후입금
  this.scnAcpnPsbData.frtmPrmPadMthCd = inputData.eltrnSaveData.frtmPrmPadMthCd // 계좌 유형
  this.scnAcpnPsbData.isDcmWcnstOmit = inputData.isDcmWcnstOmit // 191010 / isDcmWcnstOmit 서면동의생략
  this.scnAcpnPsbData.contrAthntMethCd = inputData.eltrnSaveData.contrAthntMethCd // 191106 / 계약자인증방식코드
  this.scnAcpnPsbData.insrdAthntMethCd = inputData.eltrnSaveData.insrdAthntMethCd // 191106 / 피보험자인증방식코드
  
  this.scnAcpnPsbData.corpNomnPinsYn = inputData.eltrnSaveData.corpNomnPinsYn //법인명의개인보험여부

  this.scnAcpnPsbData.prdtTypMgCd = inputData.eltrnSaveData.prdtTypMgCd //상품유형관리코드RD


  this.scnAcpnPsbData.zzorgKndCd = inputData.gafData.planInfoData[0].zzorgKndCd

  /* -------------------------- AML  처리 ------------------------- */
  
  //셋팅 여정 : MSPPS610M fn_BaseDataSetting => PSElstInitUtil.fn_EltrnSignDocService 에서 AML 관련 기본정보 셋팅
  this.scnAcpnPsbData.elstInsrdPartnNo    = (inputData.eltrnSaveData.elstInsrdPartnNo)? String(inputData.eltrnSaveData.elstInsrdPartnNo).trim()          : ''      //주피보험자 BPID
  this.scnAcpnPsbData.amlContrPartnNo     = (inputData.eltrnSaveData.amlContrPartnNo)?  String(inputData.eltrnSaveData.amlContrPartnNo).trim()           : ''     // 계약자 AML대상 BPID
  this.scnAcpnPsbData.amlPrtrPesonPartnNo = (inputData.eltrnSaveData.amlPrtrPesonPartnNo)? String(inputData.eltrnSaveData.amlPrtrPesonPartnNo).trim()    : ''       //친권자01 AML대상 BPID
  this.scnAcpnPsbData.amlPrtrChnlCustId01 = (inputData.eltrnSaveData.prtrChnlCustId01)? String(inputData.eltrnSaveData.prtrChnlCustId01).trim()          : ''     //친권자01 채널고객ID
  this.scnAcpnPsbData.amlPrtrNm01         = (inputData.eltrnSaveData.prtrNm01)? String(inputData.eltrnSaveData.prtrNm01).trim()                          : ''             //친권자01 고객명  
  this.scnAcpnPsbData.amlDthBnfrPartnNo   = (inputData.eltrnSaveData.amlDthBnfrPartnNo)? String(inputData.eltrnSaveData.amlDthBnfrPartnNo).trim()        : ''    //사망수익자01 AML대상 BPID
  this.scnAcpnPsbData.amlDthBnfrChnlCustId = (inputData.eltrnSaveData.amlDthBnfrChnlCustId)? String(inputData.eltrnSaveData.amlDthBnfrChnlCustId).trim() : ''  // 사망수익자01 채널고객ID
  this.scnAcpnPsbData.amlDthBnfrCustNm    = (inputData.eltrnSaveData.amlDthBnfrCustNm)? String(inputData.eltrnSaveData.amlDthBnfrCustNm).trim()          : ''      // 사망수익자01 채널 고객명

  //전산심사결과 계약자/친권자01/사망수익자01 AML 이행 결과 코드 (EDD or CDD)
  this.scnAcpnPsbData.gafDataAmlKycRsltCd = inputData.gafData.amlKycRsltCd
  
  this.scnAcpnPsbData.amlPrtrData01     = inputData.amlPrtrData01 // AML 친권자01 채널고객 기본정보
  this.scnAcpnPsbData.amlDthBnfrData01  = inputData.amlDthBnfrData01 //AML 사망수익자01 채널고객 기본정보

  // CEK
  let cundwInfoDto = null, cundwPolprDto = null // tmodel 전산심사 정보 
  cundwInfoDto = (inputData.gafData && inputData.gafData.tmodel && inputData.gafData.tmodel.cundwInfoDto)? inputData.gafData.tmodel.cundwInfoDto : null
  cundwPolprDto = (cundwInfoDto && cundwInfoDto.cundwPolprDto)? cundwInfoDto.cundwPolprDto : null

  if (cundwPolprDto) {
    this.scnAcpnPsbData.contrWlNvtnRsltCd = cundwPolprDto.zaFncDlRstcYn
    this.scnAcpnPsbData.contrEddSancObjScCd = cundwPolprDto.zaEddSancObjScCd
    this.scnAcpnPsbData.contrZaKycRsltCd = cundwPolprDto.zaKycRsltCd
    this.scnAcpnPsbData.bnfrWlNvtnRsltCd = cundwPolprDto.zaBnfrFncDlRstcYn
    this.scnAcpnPsbData.bnfrEddSancObjScCd = cundwPolprDto.zaBnfrEddSancObjScCd
    this.scnAcpnPsbData.bnfrZaKycRsltCd = cundwPolprDto.zaBnfrKycRsltCd
  }

  /* -------------------------- AML  처리 ------------------------- */

  // 실제소유자 상이 계약
  this.scnAcpnPsbData.returnData.ralOwnerYn = inputData.eltrnSaveData.ralOwnerYn
  // 지정청구대리인신청
  this.scnAcpnPsbData.returnData.asgnProxyClamEntYn = inputData.eltrnSaveData.asgnProxyClamEntYn
  // 개인신용정보활용동의여부
  this.scnAcpnPsbData.returnData.crinfCnsntYn = inputData.eltrnSaveData.crinfCnsntYn
  // 수익자상이여부
  this.scnAcpnPsbData.returnData.bnfrChgYn = inputData.eltrnSaveData.bnfrDfrncYn
  // 알릴의무사항 2개 이상 시 접수 불가 처리
  this.scnAcpnPsbData.returnData.nobMultpYn = inputData.eltrnSaveData.nobMultpYn
  // GA일때
  if (PSServiceManager.getUserInfo('srvcMngScCd') === 'GA') { // GA일때
    this.scnAcpnPsbData.returnData.gaOnpstYn = 'Y'
  } else {
    this.scnAcpnPsbData.returnData.gaOnpstYn = 'N'
  } // end else if
  // 외국인 신분증 진위확인 대상 일때 고액여부 체크
  if ((inputData.eltrnSaveData.idcTofCnfObjYn === 'Y' || inputData.eltrnSaveData.idcTofCnfObjYn === 'X') &&
  this.scnAcpnPsbData.knbScCd === 'ZPER02') {
    this.scnAcpnPsbData.returnData.frgnIdcTofCnfObjYn = 'Y'
  } else {
    this.scnAcpnPsbData.returnData.frgnIdcTofCnfObjYn = 'N'
  } // end if

  // TODO 가입설계 오픈시 => 당뇨 특양 선택시 당뇨유병자 특약 선택일때 현장 접수 가능
  // 현재 당뇨유병자 항목 선택시 현장 접수 가능
  // if (inputData.signerData.diabtTrtyEntYn === 'Y') {
  // 당뇨유병자
  if (inputData.eltrnSaveData.diabtNobWrtYn === 'Y') { // 선택
    this.scnAcpnPsbData.returnData.diabtWrtUnSlctYn = 'N'
  } else { // 미선택 시 접수 불가
    this.scnAcpnPsbData.returnData.diabtWrtUnSlctYn = 'Y'
  } // end else if
  // } else {
  //  this.scnAcpnPsbData.returnData.diabtWrtUnSlctYn = 'N'
  // } // end else if
  this.scnAcpnPsbData.returnData.coprClctYn = inputData.eltrnSaveData.coprClctYn // 공동모집여부
  this.scnAcpnPsbData.returnData.txbkScCd = inputData.eltrnSaveData.txbkScCd // 2020.02.21 세금우대구분코드
  this.scnAcpnPsbData.returnData.apcPrsonAplScCd = inputData.eltrnSaveData.apcPrsonAplScCd // 2020.05.31 지정대리청구인신청구분코드

  // [생체인증추가] 생체인증 등록,처리,서명실패로 인한 지점접수
  if ( inputData.hasOwnProperty('bioForceFofAcpn') ) {
    this.scnAcpnPsbData.bioForceFofAcpn = inputData.bioForceFofAcpn
    this.scnAcpnPsbData.bioForceFofAcpnMessage = inputData.bioForceFofAcpnMessage
  }
}
/************************************************************************************************
 * Function명  : fn_ScnAcpnServiceData
 * 설명        : 서비스 호출 함수 정의
                 
 ************************************************************************************************/


PSScnAcpnUtil.fn_ScnAcpnServiceData = async function (serviceName) {
  let pParams = {}
  switch (serviceName) {
    case 'txTSSPS18S3': // 유지면담 대상
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S3'))
      pParams.data = {}
      pParams.data.fpEno = PSServiceManager.getUserInfo('userEno')
      break
    case 'txTSSPS99S11': // 현장접수거부FC조회
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S11'))
      pParams.data = {}
      pParams.data.scnAcpnRstcCnsltEno = PSServiceManager.getUserInfo('userEno') // 현장접수제한컨설턴트사번
      break
    case 'txTSSPS_AML' : // AML 대응 Promise 패턴
      // 대상고객 기준정보 => AML서식입력값 조회 추출 => 기준정보 업데이트 / 조회 => AML 수행 => 결과 처리   

      //계약자
      let contrBpId          = this.scnAcpnPsbData.partnerId
      let amlContrPartnNo    = this.scnAcpnPsbData.amlContrPartnNo
      let contrCustNm        = this.scnAcpnPsbData.mnContrNm
      //친권자01
      let amlPrtrPesonPartnNo = this.scnAcpnPsbData.amlPrtrPesonPartnNo
      let amlPrtrNm01         = this.scnAcpnPsbData.amlPrtrNm01
      //사망수익자01
      let amlDthBnfrPartnNo  = this.scnAcpnPsbData.amlDthBnfrPartnNo
      let amlDthBnfrCustNm   = this.scnAcpnPsbData.amlDthBnfrCustNm

      let contrMdCustInfo    = null , prtrMdCustInfo01 = null , dthBnfrMdCustInfo01 = null
      let rsltContrAml       = null , rsltPrtrAml01 = null , rsltDthBnfrAml01 = null
      let isSetEdd           = false 
      let amlObjContrYn      = 'N' , amlObjPrtrrYn = 'N' , amlObjDthBnfrYn = 'N'
      let msgCntnt           = ''

      let amlWrtDataList = []
      let amlContrWrtSaveData = null , amlPrtrWrtSaveData = null , amlDthBnfrWrtSaveData = null // 각대상자별 AML 서식작성한 데이터

      let gafDataAmlKycRsltCd   = this.scnAcpnPsbData.gafDataAmlKycRsltCd
      let kycRsltCdContr     = (gafDataAmlKycRsltCd && gafDataAmlKycRsltCd.contr)?      gafDataAmlKycRsltCd.contr : ''
      let kycRsltCdPrtr01    = (gafDataAmlKycRsltCd && gafDataAmlKycRsltCd.prtr01)?     gafDataAmlKycRsltCd.prtr01 : ''
      let kycRsltCdDthBnfr01 = (gafDataAmlKycRsltCd && gafDataAmlKycRsltCd.dthBnfr01)?  gafDataAmlKycRsltCd.dthBnfr01 : ''


      //저장된 AML 서식 입력값 조회 ,중단건에서 진입_전자서명 앱 종료 리턴값 추출 없는 CASE) 고려 서비스 조회로 처리
      //aml 대상자 BPID 값이 있다는 것은 고객 작성 AML서식이 발행 있다는것과 같음
      // 영수증번호로 작성한 AML 서식 입력 데이터 조회 및 코드값 매핑 처리 리턴        
      if(amlContrPartnNo || amlDthBnfrPartnNo || amlPrtrPesonPartnNo) { // 한개라도 BPID가 존재 하면 저장된 AML서식 입력값 정보 조회
        amlWrtDataList = await PSAmlUtil.fn_GetSaveAmlDocWrt(null,this.scnAcpnPsbData.vuchId) 
        for(let i=0 ; i < amlWrtDataList.length ; ++i){
          if(amlWrtDataList[i].amlCustContRltnTypCd == '1'){ // 계약자용
            amlContrWrtSaveData = amlWrtDataList[i]
          }else if(amlWrtDataList[i].amlCustContRltnTypCd == '2'){ // 대리자(친권자)
            amlPrtrWrtSaveData = amlWrtDataList[i]
          }else if(amlWrtDataList[i].amlCustContRltnTypCd == '3'){ //사망수익자용
            amlDthBnfrWrtSaveData = amlWrtDataList[i]
          }
        }//for
      }
      
      //계약자 기준정보 조회 계약자는 기본으로 AML대사 수행
      contrMdCustInfo = await this.fn_GetMdCustInfoForAml(this.vueClone,contrBpId,contrCustNm)

      if(contrMdCustInfo.isError == false){
        if(amlContrPartnNo && kycRsltCdContr == 'EDD'){
          isSetEdd = true
        }else{
            isSetEdd = false
        }

        rsltContrAml   = await this.fn_ProcAmlCust(this.vueClone,'1',isSetEdd,contrMdCustInfo,this.scnAcpnPsbData,amlContrWrtSaveData)  //계약자 AML 대사
        if(rsltContrAml.isError == false){
        }else{
          amlObjContrYn = 'Y'
        }
      }else{ // 기준정보 조회 에러시
        amlObjContrYn = 'Y' 
      } 

      //계약자, 수익자가 존재
      //계약자 또는 수익자 둘 중 하나라도 EDD면 현장접수 불가
      if(amlDthBnfrPartnNo){
        if(kycRsltCdContr == 'EDD' || kycRsltCdDthBnfr01 == 'EDD'){
          if(kycRsltCdContr == 'EDD'){
            amlObjContrYn = 'Y'
          }
          if(kycRsltCdDthBnfr01 == 'EDD'){
            amlObjDthBnfrYn = 'Y'
            dthBnfrMdCustInfo01 = await this.fn_GetMdCustInfoForAml(this.vueClone, amlDthBnfrPartnNo,amlDthBnfrCustNm,true) //사망수익자01 기준정보 조회 
          }          
        }else{
          amlObjContrYn = 'N' //통과
          amlObjDthBnfrYn = 'N' //통과
        }
      //계약자만 존재, 계약자가 EDD면 현장접수 불가
      }else{
        if(kycRsltCdContr == 'EDD'){
          amlObjContrYn = 'Y'
        }else{
          amlObjContrYn = 'N' //통과
        }
      }
      
      //한개라도 AML 블록킹 Y일때 진행 차단
      if(amlObjContrYn == 'X' || amlObjDthBnfrYn == 'X'){
        this.scnAcpnPsbData.returnData.amlObjYn = 'X'
      } else if(amlObjContrYn == 'Y' || amlObjDthBnfrYn == 'Y'){
        this.scnAcpnPsbData.returnData.amlObjYn = 'Y'
      }

      this.scnAcpnPsbData.returnData.msgCntnt += msgCntnt
      console.log('AML 결과 메세지')
      console.log(this.scnAcpnPsbData.returnData.msgCntnt)


      //현장접수불가 사유 AML 블로킹 대상자 라벨 처리
      let amlObjTarget = ''
      if(amlObjContrYn == 'X' || amlObjContrYn == 'Y'){
        amlObjTarget = (amlContrPartnNo)?  '계약자' : '' // 계약자 AML BPID로 AS-IS / TO-BE 구분 라벨 처리
      }

      if(amlObjDthBnfrYn == 'X' || amlObjDthBnfrYn == 'Y'){
        amlObjTarget = (amlObjTarget)?  amlObjTarget + ',' : ''
        amlObjTarget += '사망수익자01'
      }
           
      this.scnAcpnPsbData.returnData.amlObjTarget = (amlObjTarget)? '['+ amlObjTarget +']' : ''
      
      //AS-IS 루틴  임직원여부 조회
      PSScnAcpnUtil.fn_ScnAcpnServiceData('txTSSPS18S7')

      //break 아닌 return 처리에 유의   
      return  
    
    case 'txTSSPS18S7': // 임직원여부 조회
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S7'))
      pParams.data = {}
      pParams.data.vuchId = this.scnAcpnPsbData.vuchId // 영수증 번호
      pParams.data.custId = this.scnAcpnPsbData.partnerId // 고객ID(BP ID)
      if (this.scnAcpnPsbData.knbScCd === 'ZPER01') { // 내국인
        pParams.data.custNm = this.scnAcpnPsbData.mnContrNm // 고객명 - 내국인인경우 필요
      } else { // 외국인
        pParams.data.custNm = this.scnAcpnPsbData.custEngNm // 고객명
      } // end else if
      break
    case 'txTSSPS18S4': // 지점 소속 주임사번 조회
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S4'))
      pParams.data = {}
      pParams.data.pesnDeptCd = PSServiceManager.getUserInfo('userDeptCd') // 인사부서코드 : 지점
      break
    case 'txTSSPS98UA': // 현장접수불가사유 저장
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98UA'))
      pParams.data = {}
      pParams.data.vuchId = this.scnAcpnPsbData.vuchId // 영수증 번호
      pParams.data.scnAcpnDalwRsnCntnt = this.scnAcpnDalwRsnCntnt
      break
    default:
      break
  }
  //pParams.srnId = 'TSSPS180M'
  pParams.srnId = 'MSPPS610M'
  pParams.context = this.vueClone
  PSServiceManager.invoke(pParams, this.fn_ScnAcpnServiceResultHandler, serviceName, this.fn_ScnAcpnServiceFaultHandler)
}
/************************************************************************************************
 * Function명  : fn_ScnAcpnServiceResultHandler
 * 설명        : 서비스 호출 결과 함수 정의
 ************************************************************************************************/
PSScnAcpnUtil.fn_ScnAcpnServiceResultHandler = function (event, serviceName) {
  let lv_data = event.data
  switch (serviceName) {
    case 'txTSSPS18S3': // 유지면담 대상
      let newDate = PSDateUtil.fn_CurrentDate()
      let aplcStrYmd = lv_data.aplcStrYmd // 적용시작일자
      let aplcEndYmd = lv_data.aplcEndYmd // 적용종료일자
      // 종료일이 당일보다 큰 경우 불가처리
      if (aplcStrYmd <= newDate && aplcEndYmd >= newDate) {
        this.scnAcpnPsbData.returnData.ukepInvwObjYn = 'Y'
      } else {
        this.scnAcpnPsbData.returnData.ukepInvwObjYn = 'N'
      } // end else if
      this.fn_ScnAcpnServiceData('txTSSPS99S11')
      break
    case 'txTSSPS99S11': // 현장접수거부FC조회
      if (String(lv_data.scnAcpnRstcYm).trim() !== '') {
        this.scnAcpnPsbData.returnData.scnAcpnRstcYn = 'Y'
      } else {
        this.scnAcpnPsbData.returnData.scnAcpnRstcYn = 'N'
      } // end else if
      //this.fn_ScnAcpnServiceData('txTSSPS18S6')

      // this.fn_ScnAcpnServiceData('txTSSPS_AML') 에서 'txTSSPS18S7' 임직원여부 조회 분기 처리함
      this.fn_ScnAcpnServiceData('txTSSPS_AML') 
      break  
    case 'txTSSPS18S7': // 임직원여부 조회
      this.scnAcpnPsbData.returnData.selfContYn = lv_data.scnAcpnRstcYn
      this.fn_ScnAcpnServiceData('txTSSPS18S4')
      break
    case 'txTSSPS18S4': // 지점 소속 주임사번 조회
      let fofOfcwkClerkList = lv_data.fofOfcwkClerkList
      if (fofOfcwkClerkList && fofOfcwkClerkList.length > 0) {
        this.scnAcpnPsbData.returnData.fofOfwrHldofYn = 'N'
      } else {
        this.scnAcpnPsbData.returnData.fofOfwrHldofYn = 'Y'
      } // end else if
      this.fn_ScnAcpnChack()
      break
    default:
      break
  }
}
/************************************************************************************************
 * Function명  : fn_ScnAcpnServiceFaultHandler
 * 설명        : 에러 이벤트
 ************************************************************************************************/
PSScnAcpnUtil.fn_ScnAcpnServiceFaultHandler = async function (event, serviceName) {
  switch (serviceName) {
    case 'txTSSPS18S3': // 유지면담 대상
      this.scnAcpnPsbData.returnData.ukepInvwObjYn = 'Y'
      this.fn_ScnAcpnServiceData('txTSSPS99S11')
      break
    case 'txTSSPS99S11': // 현장접수거부FC조회
      this.scnAcpnPsbData.returnData.scnAcpnRstcYn = 'Y'
      //this.fn_ScnAcpnServiceData('txTSSPS18S6')
      await this.fn_ScnAcpnServiceData('txTSSPS_AML')
      break
    case 'txTSSPS_AML': // AML 대응
      this.scnAcpnPsbData.natyCd = '' // 국가 코드
      this.scnAcpnPsbData.custEngNm = '' // 영문이름
      this.scnAcpnPsbData.returnData.amlObjYn = 'Y'
      this.fn_ScnAcpnServiceData('txTSSPS18S7')
      break
    
    case 'txTSSPS18S7': // 임직원여부 조회
      this.scnAcpnPsbData.returnData.selfContYn = 'Y'
      this.fn_ScnAcpnServiceData('txTSSPS18S4')
      break
    case 'txTSSPS18S4': // 지점 소속 주임사번 조회
      this.scnAcpnPsbData.returnData.fofOfwrHldofYn = 'Y'
      this.fn_ScnAcpnChack()
      break
    default:
      break
  }
}
/************************************************************************************************
 * Function명  : fn_ScnAcpnChack
 * 설명        : 조회한 Data 체크 하여 접수 불가건 확인
 ************************************************************************************************/
PSScnAcpnUtil.fn_ScnAcpnChack = function () {
  this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'Y' // 현장접수가능여부
  this.scnAcpnDalwRsnCntnt = '' // 현장접수불가 메시지 N건
  let acpnChackMsg = '' // 현장 접수 불가 메세지
  let defaultMsg = '지점에서 접수 가능합니다.'

  if (this.scnAcpnPsbData.returnData.selfContYn === 'Y') { // 본인계약여부

    let zzorgKndCd = this.scnAcpnPsbData.zzorgKndCd
    console.log("zzorgKndCd : " + zzorgKndCd)
    
    // zzorgKndCd 18:전문직, 25:파트너
    if(zzorgKndCd !== "18" && zzorgKndCd !== "25"){ 
      this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
      // 임직원 본인/가족계약건은 지점에서 접수가능합니다.
      // 2021.05.06 ASR210400915 (사랑온 청약심사) 가족계약 현장접수 불가로직 해제
      if (acpnChackMsg !== '') {
        acpnChackMsg += ', FC 또는 임직원 본인계약건'
      } else {
        acpnChackMsg += 'FC 또는 임직원 본인계약건'
      } // end else if
    }else{
      console.log("신채널 구분코드값인 경우 본인계약인 경우 현장접수 불가처리 예외적용")
      console.log("zzorgKndCd : " + zzorgKndCd)
    }
    
  } // end if

  if (this.scnAcpnPsbData.returnData.ralOwnerYn === 'N') { // 실제소유자 상이 계약
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    // 실제소유자 상이 계약 건은 지점에서 접수가능합니다.
    if (acpnChackMsg !== '') {
      acpnChackMsg += ', 실제소유자 상이 계약 건'
    } else {
      acpnChackMsg += '실제소유자 상이 계약 건'
    } // end else if
  } // end if

  
  if (this.scnAcpnPsbData.returnData.nobMultpYn === 'Y') { // 알릴의무사항 2개 이상 시 접수 불가 처리
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    // 주종피 알릴의무양식 상이건은 지점에서 접수가능합니다
    if (acpnChackMsg !== '') {
      acpnChackMsg += ', 주종피 알릴의무양식 상이건'
    } else {
      acpnChackMsg += '주종피 알릴의무양식 상이건'
    } // end else if
  } // end if

  // 4월 15일 이후 적용대상 공동모집 대상 건은 현장 접수 불가 처리
  if (this.scnAcpnPsbData.returnData.coprClctYn === 'Y' || this.scnAcpnPsbData.returnData.coprClctYn === 'X') {
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    // 임직원 본인/가족계약건은 지점에서 접수가능합니다.
    if (acpnChackMsg !== '') {
      acpnChackMsg += ', 공동모집 대상 건'
    } else {
      acpnChackMsg += '공동모집 대상 건'
    } // end else if
  } // end if

  
  // 1.1 멀티오류 메시지 설정
  if (this.scnAcpnPsbData.returnData.scnAcpnPsbYn === 'N') {
    acpnChackMsg += '은 지점에서 접수 가능합니다.'
    this.scnAcpnDalwRsnCntnt = acpnChackMsg
  }

  // 2. 단일 메시지
  if (this.scnAcpnPsbData.returnData.crinfCnsntYn === 'N') { // 개인신용정보활용동의여부
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    acpnChackMsg = `개인 신용정보처리 직접 동의 '아니요' 체크건은 접수불가합니다`
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += `\n개인 신용정보처리 직접 동의 '아니요' 체크건은 접수불가합니다`
    } else {
      this.scnAcpnDalwRsnCntnt = `개인 신용정보처리 직접 동의 '아니요' 체크건은 접수불가합니다`
    }
  } // end if

  if (this.scnAcpnPsbData.returnData.ukepInvwObjYn === 'Y') { // 유지면담대상여부
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    acpnChackMsg = defaultMsg
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += '\n선별인수 대상FC로 지점에서 접수가능합니다.'
    } else {
      this.scnAcpnDalwRsnCntnt = '선별인수 대상FC로 지점에서 접수가능합니다.'
    }
  } // end if

  if (this.scnAcpnPsbData.returnData.scnAcpnRstcYn === 'Y' ) { // 현장접수거부FC조회
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    acpnChackMsg = defaultMsg
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += '\n선별인수 대상FC로 지점에서 접수가능합니다.'
    } else {
      this.scnAcpnDalwRsnCntnt = '선별인수 대상FC로 지점에서 접수가능합니다.'
    }
  } // end if

  //대상자 구분
  let amlObjTarget = (this.scnAcpnPsbData.returnData.amlObjTarget) ? this.scnAcpnPsbData.returnData.amlObjTarget : ''
  if (this.scnAcpnPsbData.returnData.amlObjYn === 'Y') { // AML대상여부
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    acpnChackMsg = defaultMsg
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += '\n자금세탁방지(AML) 확인필요 건으로 지점에서 확인 후 접수가능합니다.'+ amlObjTarget
    } else {
      this.scnAcpnDalwRsnCntnt = '자금세탁방지(AML) 확인필요 건으로 지점에서 확인 후 접수가능합니다.'+ amlObjTarget
    }
    
  } // end if

  // 20200131 : 하춘우 프로 문구 정의
  if (this.scnAcpnPsbData.returnData.amlObjYn === 'X') { // AML대상여부 조회 실패 시
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    acpnChackMsg = defaultMsg
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += '\n자금세탁방지(AML)대상여부 조회실패로 지점에서 접수바랍니다.'+amlObjTarget
    } else {
      this.scnAcpnDalwRsnCntnt = '자금세탁방지(AML)대상여부 조회실패로 지점에서 접수바랍니다.'+amlObjTarget
    }
    
  } // end if

  if (this.scnAcpnPsbData.returnData.fofOfwrHldofYn === 'Y') { // 지점 소속 주임사번 조회
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    acpnChackMsg = defaultMsg
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += '\n소속점포 재직 지점프로가 없는 경우 지점에서 접수바랍니다.'
    } else {
      this.scnAcpnDalwRsnCntnt = '소속점포 재직 지점프로가 없는 경우 지점에서 접수바랍니다.'
    }
  } // end if

  // isDcmWcnstOmit: ' ' // 191010 / isDcmWcnstOmit 서면동의생략
  if (this.scnAcpnPsbData.isDcmWcnstOmit === 'Y') {
    // 피보험자 서면동의서생략이 Y인경우 현장접수 불가
    // 1. 유니닥스에서 전자서명에서 최종서명 완료이고(signature:true), 서면동의서 촬영을 건너뛰기 한경우에만  'Y' 설정하여 return
    // 2. PSDocFormUtil.js > DocForm.getJsonData 에서 isDcmWcnstOmit 값을 설정
    // 3. TSSPS180M.vue > fn_PSElstDtlInfoVOSetting 에서 isDcmWcnstOmit 값을 설정
    // 4. PSScnAcpnUtil > fn_GetInputData 값을 설정

    // [생체인증추가] 2020.06 아래 전체주석 && 아래에서 분기
    // this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    // acpnChackMsg = '피보험자 서면동의서 촬영 건너뛰기 선택 건은 지점에서 원본확인 후 접수가능합니다.'
    // if (this.scnAcpnDalwRsnCntnt !== '') {
    //   this.scnAcpnDalwRsnCntnt += '\n'
    //   this.scnAcpnDalwRsnCntnt += acpnChackMsg
    // } else {
    //   this.scnAcpnDalwRsnCntnt = acpnChackMsg
    // }

    // [생체인증추가] 생체인증 등록,처리,서명실패로 인한 지점접수
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    if ( this.scnAcpnPsbData.hasOwnProperty('bioForceFofAcpn') && this.scnAcpnPsbData.bioForceFofAcpn === true ) {
      acpnChackMsg = this.scnAcpnPsbData.bioForceFofAcpnMessage
    } else {
      acpnChackMsg = '피보험자 서면동의서 촬영 건너뛰기 선택 건은 지점에서 원본확인 후 접수가능합니다.'
    }
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += '\n'
      this.scnAcpnDalwRsnCntnt += acpnChackMsg
    } else {
      this.scnAcpnDalwRsnCntnt = acpnChackMsg
    }
  }

  // 2020.02.21 비과세 전자서명 현장접수 가능여부 화면 로직수정
  if (this.scnAcpnPsbData.returnData.txbkScCd !== '8' && this.scnAcpnPsbData.returnData.txbkScCd !== '' && this.scnAcpnPsbData.returnData.txbkScCd !== ' ') {
    this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
    acpnChackMsg = '세금우대 신청을 원하시는 경우 지점에서 접수하셔야 합니다.'
    if (this.scnAcpnDalwRsnCntnt !== '') {
      this.scnAcpnDalwRsnCntnt += '\n'
      this.scnAcpnDalwRsnCntnt += acpnChackMsg
    } else {
      this.scnAcpnDalwRsnCntnt = acpnChackMsg
    }
  }

  let corpNomnPinsYn = this.scnAcpnPsbData.corpNomnPinsYn
  let prcd = this.scnAcpnPsbData.prcd.substr(0,6)
  let prdtTypMgCd = this.scnAcpnPsbData.prdtTypMgCd
  let admintFprdPrcdYn = 'N' //경영인정기상품여부

  if (prdtTypMgCd && prdtTypMgCd.length > 0){
    let splitList = prdtTypMgCd.split(',')
    if (splitList && splitList.length > 0) {
      console.log('zzzzzzzzzzz  splitList.length' + splitList.length)
      splitList.forEach(item => {
        console.log('zzzzzzzzzzz  prdtTypMgCd' + item)
        if(item === 'W8'){
          admintFprdPrcdYn = 'Y'
        }
        console.log('zzzzzzzzzzz  admintFprdPrcdYn' + admintFprdPrcdYn)
      })
    }
  }

  console.log('zzzzzzzzzzz  prcd' + prcd)
  console.log('zzzzzzzzzzz  corpNomnPinsYn' + corpNomnPinsYn)
  
  if(admintFprdPrcdYn === "Y" || (prcd ==="LP0524" || prcd ==="LP0560" || prcd ==="LP0722" || prcd === "LP0723")){
    if(corpNomnPinsYn ==="N"){
      this.scnAcpnPsbData.returnData.scnAcpnPsbYn = 'N'
      acpnChackMsg = defaultMsg
      if (this.scnAcpnDalwRsnCntnt !== '') {
        this.scnAcpnDalwRsnCntnt += '\n사업자등록증 필수첨부 대상입니다. 사업자등록증 구비하시어 지점접수 바랍니다.'
      } else {
        this.scnAcpnDalwRsnCntnt = '사업자등록증 필수첨부 대상입니다. 사업자등록증 구비하시어 지점접수 바랍니다.'
      }
    }
  }

  // 현장접수 가능여부 == N인경우 재조회 현장접수불가사유를 등록함
  if (this.scnAcpnPsbData.returnData.scnAcpnPsbYn === 'N') {
    this.fn_ScnAcpnServiceData('txTSSPS98UA')
  }
  this.fnScnAcpnCallBack({returnData: this.scnAcpnPsbData.returnData, error: this.scnAcpnDalwRsnCntnt})
}
/******************************************************************************
 * Function명  : PSScnAcpnUtil.fn_GetMdCustInfoForAml
 * 설명        : AML 대상 수행 고객 기준정보 조회
 *              BPID로 고객 기준정보 조회
 ******************************************************************************/
PSScnAcpnUtil.fn_GetMdCustInfoForAml = async function(context,custBpId,custNm){
  let pParams = {}
  let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'

  //pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S6')) //조회결과 고객정보 평문
  pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S10'))//조회결과 고객정보 암호화 C339_F1MDI0386_S

  pParams.srnId = srnId 
  pParams.data = {}  
  pParams.data.BUSINESSPARTNER = {}
  pParams.data.BUSINESSPARTNER.ACTIVITY_CODE = 'R' // 조회:R / 변경:U
  pParams.data.BUSINESSPARTNER.PARTNER = custBpId   //BP ID
  pParams.data.BUSINESSPARTNER.GENERALDATA_S = {}
  pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_CONTROL = {}
  pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_CONTROL.CATEGORY = '1' // 개인(1)/법인(2)
  pParams.data.IS_HEADER = {}
  pParams.data.IS_HEADER.USERID = PSServiceManager.getUserInfo('userEno') // 처리자 사번
  pParams.data.IS_HEADER.ZA_BUSN_TRT_PATH_CD = '2220' // 업무처리경로코드
  pParams.data.IS_HEADER.ZA_TRT_SYSTM_PATH_CD = '22' // 처리시스템경로코드
  pParams.data.IS_HEADER.CHDAT = '' // 처리일자
  pParams.data.IS_HEADER.CHTIM = '' // 처리시간

  let rsltData = await PSServiceManager.invokeSendAsync(pParams) // 고객기준정보 조회 실행

  if(rsltData.isError){
   return rsltData 
  }

  let knbScCd     = rsltData.data.businesspartner.idnumbers_T[0].identificationtype   // 주민번호 유형: ZPER01: 주민등록번호, ZPER02: 외국인등록번호, ZPER03: 여권식별번호
  let custEngNm   = rsltData.data.businesspartner.generaldata_S.bp_PERSON.secondname  // 영문이름
  let natyCd      = rsltData.data.businesspartner.generaldata_S.bp_PERSON.nationality // 국가 코드
  let jobCd       = rsltData.data.businesspartner.jobdata_S.za_INSR_JOB_CD             // 기준정보 직업코드 "012212" 형태  
  let zzDwlrScCd  = rsltData.data.businesspartner.generaldata_S.extentions.zz_DW_SC_CD //기준정보 01:국내/02:국외
  

  let rtnData ={
      custBpId  : custBpId //대상고객 BPID
    , custNm    : custNm //고객명    
    , custEngNm : (custEngNm)? String(custEngNm).trim() : '' // 고객명 영문
    , natyCd    : (natyCd)? String(natyCd).trim() : '' //국적코드
    , knbScCd   : knbScCd  //대상고객 내국인 ZPER01, 외국인 구분S    
    , jobCd     : jobCd
    , zzDwlrScCd : (zzDwlrScCd)? String(zzDwlrScCd).trim() : ''
    , isError   : false
    }

  return rtnData
 }
 /******************************************************************************
 * Function명  : PSScnAcpnUtil.fn_ProcAmlCust
 * 설명        : AML 대사 수행
 *              대상고객 기준정보 호출 국적과 외국인여부 조회결과 값으로
 *              AML 수행
               context : 호출 화면 this
               isSetEdd : EDD 포함 파라미터 셋팅
               amlCustRltnTypCd : AML 고객 구분 1: 계약자 , 2 : 대리인(친권자) , 3 : 사망수익자
               pMdCust : PSAmlUtil.fn_GetMdCustInfoForAml 호출 결과 값 (기준정보)
               scnAcpnPsbData : 접수처리 관련 정보
               amlSaveData : 서식입력정보(테이블 저장된 데이타)               
 ******************************************************************************/
               
PSScnAcpnUtil.fn_ProcAmlCust = async function(context,amlCustRltnTypCd,isSetEdd,pMdCustAml,scnAcpnPsbData,amlSaveData){

  // AML 파라미터 셋팅
  let pParams = {}
  let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'
  let dwlrScCd = ''
  let jobCd = ''

  //pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S6'))//AS-IS  C339_F10670028_S
  pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S11'))//AML 대응 C340_F10670020_S
  pParams.srnId = srnId

  pParams.data = {}
  // 고정값
  pParams.data.prcsPrfmcScCd  = '3' // 고정값 3:모두확인정보의옵션임
  pParams.data.amlCustTypCd   = '1' // 고정값 AML고객유형코드 1:개인/2:단체
  pParams.data.amlBusnDtlScCd = 'NB02' // 고정값 AML업무상세구분코드
  pParams.data.dlChnlCd       = '12' // 고정값 거래채널코드
  pParams.data.trtFofOrgNo    = PSServiceManager.getUserInfo('trtFofOrgNo') // 처리지점조직번호
  pParams.data.trtrEno        = PSServiceManager.getUserInfo('trtrEno') // 처리자사번
  pParams.data.ralOwnerYn     = 'Y' // 실제소유자여부 Y:실소유자/N:아닌경우
  pParams.data.custId         = pMdCustAml.custBpId // 파트너ID 대상고객 BPID

  //AML_CUST_CONT_RLTN_TYP_CD(AML고객계약관계유형코드) 계약자 1 세팅
  pParams.data.custScCd       = '1' //고정값 계약자 1, 수익자 3

  if (pMdCustAml.knbScCd === 'ZPER01') { // 내국인
    pParams.data.custNm       = pMdCustAml.custNm //this.scnAcpnPsbData.mnContrNm // 고객명 - 내국인인경우 필요
    pParams.data.custEngNm    = '' // 영문 고객명 
    pParams.data.natyCd       = (pMdCustAml.natyCd)? pMdCustAml.natyCd : 'KR'  // 국적코드 (bp 정보 그대로 입력해야 함)    
  } else { // 외국인
    pParams.data.custNm       = pMdCustAml.custEngNm // 고객명 - 내국인인경우 필수
    pParams.data.custEngNm    = pMdCustAml.custEngNm // 고객명 - 외국인인경우 필수
    pParams.data.natyCd       = pMdCustAml.natyCd // 국적코드 (기준정보 조회한 natyCd 입력)
  } // end else if

  if(amlCustRltnTypCd == '1'){ // 거주지정보 셋팅 계약자 일때
    dwlrScCd = scnAcpnPsbData.contrDwScCd // 계약자 사전입력단계에서 입력한 거주지정보 값 셋팅 (TTSD00002테이블저장된값)
    jobCd    = scnAcpnPsbData.jobCd      // 계약자 채널 직업코드
  }else { // 이외...
    
    if(pMdCustAml.zzDwlrScCd){
      dwlrScCd = pMdCustAml.zzDwlrScCd // 거주지정보 기준정보에 값이 있다면 있는 값으로 셋팅(기고객의 기계약으로 값이 있을경우)
    }else{
      dwlrScCd = '01' // 없으면사망수익자01/ 친권자 01:국내로 기본 신규고객으로 필동 받을시 거주지 입력 항목이 없어 빈값
    }

    //jobCd = pMdCustAml.jobCd // 기준정보의 직업코드 
    
    if(amlCustRltnTypCd == '2'){ //친권자
      jobCd = scnAcpnPsbData.amlPrtrData01.jobCd //친권자 채널 직업코드
    } else if(amlCustRltnTypCd == '3'){ //수익자
      jobCd = scnAcpnPsbData.amlPrtrData01.jobCd //사망수익자 채널 직업코드
    }

  }

  let contEntRsnCd = '' , fdSrcScCd = '' , jobInfoScCd = '' , asptnAsetScCd= ''
  if(amlSaveData){ //저장된 AML 서식 입력 정보
    contEntRsnCd  = (amlSaveData.contEntRsnCd)?  String(amlSaveData.contEntRsnCd).trim()  : '' //서식에 기입한 거래목적 코드 매칭
    fdSrcScCd     = (amlSaveData.fdSrcScCd)?     String(amlSaveData.fdSrcScCd).trim()     :''  //서식에 기입한 자금원천 코드 매칭값
    jobInfoScCd   = (amlSaveData.jobScCd)?       String(amlSaveData.jobScCd).trim()       :''  //서식에 기입한 직업정보 코드 매칭
    asptnAsetScCd = (amlSaveData.asptnAsetScCd)? String(amlSaveData.asptnAsetScCd).trim() :''  //서식에 기입한 자산총액 코드 매칭값
  }

  pParams.data.dwlrScCd       = dwlrScCd  //거주자구분코드 01:국내/02:국외
  pParams.data.insrJobCd      = jobCd     //보험직업코드(기준정보 조회한 직업코드 "012212" 형태 businesspartner.jobdata_S.za_INSR_JOB_CD)  

  //----------------------------- EDD 추가 정보 셋팅 ------------------------------------------------------

  if(isSetEdd){
    pParams.data.eddAddmInfoInptYn     = 'Y' //EDD추가정보입력여부 'Y'    
    pParams.data.contEntRsnCd          = contEntRsnCd //계약가입사유코드 서식의 거래목적 항목
    pParams.data.fdOrgplScCd           = fdSrcScCd //자금출처구분코드 서식의 자금원천 항목
    //서식의 직업정보 1:직장인 / 2:개인사업자 / 3:무직/학생/파악불가  => 대칭 파라미터 못찾음
    //서식의 재무정보  연소득(만원) => 대칭 파라미터 없음
    //서식의 자산총액(10억미만 / 10~100억 / 100~1,000억 / 1,000억 초과)  => 대칭 파라미터 못찾음
  }

  //------------------------------ 계약정보 셋팅 ---------------------------------------------------------

  let refrNo = scnAcpnPsbData.vuchId // 참조번호 KYC 처리내역 확인/변경 -> AS-IS AML 영수증번호 보냄  , TO-BE AML 영수증번호+대상고객BPID 

  if(amlCustRltnTypCd == '1' && scnAcpnPsbData.amlContrPartnNo){ //계약자 AML BPID 존재시 TO-BE AML 대사 파라미터 구성
    refrNo += scnAcpnPsbData.amlContrPartnNo;
  }else if(amlCustRltnTypCd == '2'){ // 친권자 TO-BE AML 대사 파라미터 구성
    refrNo += scnAcpnPsbData.amlPrtrPesonPartnNo;
  }else if(amlCustRltnTypCd == '3'){
    refrNo += scnAcpnPsbData.amlDthBnfrPartnNo //사망수익자 TO-BE AML 대사 파라미터 구성
  }

  //AML_CUST_RLTN_TYP_CD(AML고객관계유형코드)
  // if(refrNo != scnAcpnPsbData.vuchId){ //참조번호가 영수증번호 와 같지않다면 TO-BE 일때 파라미터 amlCustRltnTypCd 셋팅 전달 , AS-IS 구성안함
  //   pParams.data.amlCustRltnTypCd = amlCustRltnTypCd //AML고객관계유형코드 계약자 1 , 대리인(친권자) 2 , 수익자(사망수익자) 3  
  // }

  pParams.data.refrNo           = refrNo 
  pParams.data.prcd             = scnAcpnPsbData.prcd // 상품코드  
  pParams.data.trtAmt01         = {}
  pParams.data.trtAmt01.amount  = scnAcpnPsbData.smtotPrm // 보험료 처리금액
  pParams.data.trtAmt02         = {}
  pParams.data.trtAmt02.amount  = scnAcpnPsbData.smtotPrm // 보험료 처리금액
  let padCylCd                  = scnAcpnPsbData.payfrqCd // 납입기간유형코드 00 일시납, 01 연납, 12  월납, 04 분기납

  let convPadCylCd = ''
  if (padCylCd === '00') {
    convPadCylCd = '99' // 일시납
  } else if (padCylCd === '12') {
    convPadCylCd = '01' // 월납
  } else if (padCylCd === '06') {
    convPadCylCd = '02' // 2월납
  } else if (padCylCd === '04') {
    convPadCylCd = '03' // 3월납
  } else if (padCylCd === '02') {
    convPadCylCd = '06' // 6월납
  } else if (padCylCd === '01') {
    convPadCylCd = '12' // 년납
  } else {
    convPadCylCd = '01'
  } // end else if

  pParams.data.padCylCd = convPadCylCd

  let rsltDataAml = await PSServiceManager.invokeSendAsync(pParams) // AML대사 서비스 실행

  if(rsltDataAml.isError){
    rsltDataAml.isError = true
  } else {
    rsltDataAml.isError = false
  }
  return rsltDataAml
}
export default PSScnAcpnUtil
// eslint-disable-next-line

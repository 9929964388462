/**
 * <PRE>
 * System Name : 신태블릿 영업지원 시스템 (TSS)
 * Business Name : 전자서명
 * Class Name : PSGAFUtil.js
 * Description :
 *              전자서명 심사결과 Data 호출 및 고객정보 공통 유틸
 * </PRE>
 * @version 1.0
 * @author Copyright (C) 2010 by SAMSUNG SDS co.,Ltd. All right reserved.
 */
import DateUtil from '@/ui/fs/comm_elst/exDateUtils'

const PSGAFUtil = {}
/**
 * 구분
 */
PSGAFUtil.gbObj = {11: '계약자', 21: '주피', 23: '종피', 24: '자녀'}

/**
 * 성별
 * */
PSGAFUtil.sexObj = {'1': '여', '001': '여', '2': '남', '002': '남'}

/**
 * 직업관련 위험등급 텍스트변환
 */
PSGAFUtil.jobRiskToTextMapping = {'0': 'A', '1': 'E', '2': 'D', '3': 'C', '4': 'B'}

/**
 * 상해 등급 텍스트변환
 * */
PSGAFUtil.jobRiskToTextMappingForInjry = {'0': '비', '1': '고', '2': '고', '3': '중', '4': '중'}

/************************************************************************************************
 * Function명  : fn_ServiceDataResultHandler
 * 설명        : 계약자/피보험자/수익자 셋팅
 ************************************************************************************************/
PSGAFUtil.fn_PICustInfoAll = function (resData) {
  let planInfoData = this.fn_PIPlanInfo(resData) // 상품정보
  let hoshInfoList = this.fn_PIHoshInfo(resData) // 계약자/피보험자 정보
  let bnfrInfoList = this.fn_PIBnfrInfo(resData) // 수익자 정보
  let custInfoAll = {
    planInfoData: planInfoData,
    hoshInfoList: hoshInfoList,
    bnfrInfoList: bnfrInfoList
  }
  return custInfoAll
}
/************************************************************************************************
 * Function명  : fn_PIHoshInfo
 * 설명        : 계약자/피보험자/종피보헙자 셋팅
 ************************************************************************************************/
PSGAFUtil.fn_PIHoshInfo = function (resData) {
  // 계약자/피보험자 셋팅
  let rltnrInfo = resData.tmodel.rltnrInfo // this.gaf.getTObj('rltnrInfo') // 관계자 중복제거 정보x
  let acData = []

  rltnrInfo.forEach(el => {
    // 계약자의 차종, 취미코드는 비운전및  없음으로 고정
    let carKnd = ''
    if (el.zzcontvInsrdCd === '11') {
      carKnd = '00'
    } else if (this.isObjectEmpty(el, 'zzcarKndScCd', true)) {
      carKnd = '00'
    } else {
      carKnd = el.zzcarKndScCd
    } // end else if

    // 계약자의 차종, 취미코드는 비운전및  없음으로 고정
    let hbyKnd = ''
    if (el.zzcontvInsrdCd === '11') {
      hbyKnd = '00'
    } else if (this.isObjectEmpty(el, 'zzhbyCd', true)) {
      hbyKnd = '00'
    } else {
      hbyKnd = el.zzhbyCd
    } // end else if

    acData.push({
      /* 원본 Data */
      // orgData: el,
      /* 구분(고객관계코드 명) */
      contvInsrdNm: this.gbObj[this.getObjValue(el, 'zzcontvInsrdCd')],
      /* 고객관계코드 */
      contvInsrdCd: this.getObjValue(el, 'zzcontvInsrdCd'),
      /* 성명 */
      insrdNm: this.getObjValue(el, 'zaInsrdNm'),
      /* 연령 */
      age: this.getObjValue(el, 'ageatentryVl') === '' ? '0' : this.getObjValue(el, 'ageatentryVl'),
      /* 주민번호 */
      custRrnId: this.getObjValue(el, 'zzcustRrnId'),
      /* 국적 */
      cusDtlTypCd: this.getObjValue(el, 'zzcusDtlTypCd'),
      /* 고객 ID */
      partnerId: this.getObjValue(el, 'partnerId'),
      /* 고객 채널 ID */
      agtFileId: this.getObjValue(el, 'zaAgtFileId'),
      /* 자녀일련번호 */
      chldSnoVl: this.getObjValue(el, 'zzchldSnoVl'),
      /* 직업 */
      insrJobCd: this.getObjValue(el, 'zzinsrJobCd'),
      insrJobNm: this.getObjValue(el, 'zaInsrJobNm'),
      /* 차종 */
      carKndScCd: carKnd,
      /* 취미 */
      hbyCd: hbyKnd
    })
  }) // end forEach el
  return acData
}
/************************************************************************************************
 * Function명  : fn_PIBnfrInfo
 * 설명        : 수익자 셋팅
 ************************************************************************************************/
PSGAFUtil.fn_PIBnfrInfo = function (resData) {
  let acData = []

  // 관계자 중복제거 정보
  // let rltnrInfo = this.gaf.getTObj('rltnrInfo')
  // 수익자 Tmodel 정보
  let bnfrInfo = resData.tmodel.bnfrInfo // this.gaf.getTObj('bnfrInfo')

  let isKeyName = false
  bnfrInfo.forEach(el => {
    // 계약/보험 대상자 구분명
    let keyName = ''
    if (this.getObjValue(el, 'zzbnfrRolCd') === '34' || this.getObjValue(el, 'zzbnfrRolCd') === '35' || this.getObjValue(el, 'zzbnfrRolCd') === '37') {
      keyName = '보험대상자'
    } else {
      // key = '계약대상자_11_0_' + bnfTyp;
      // 계약레벨은 한개만 생성
      if (!isKeyName) {
        keyName = '계약대상자'
        isKeyName = true
      } // end if
    } // end else if

    acData.push({
      /* 원본 Data */
      // orgData: el,
      /* 대상 */
      keyName: keyName,
      /* 고객관계명 */
      contvInsrdNm: this.gbObj[this.getObjValue(el, 'zzcontvInsrdCd')],
      /* 고객관계코드 */
      contvInsrdCd: this.getObjValue(el, 'zzcontvInsrdCd'),
      /* 고객명 */
      custNm: this.getObjValue(el, 'custNm'),
      /* 연령 */
      age: !this.getObjValue(el, 'knb') ? '0' : DateUtil.getInsuranceAge(this.getObjValue(el, 'knb'), DateUtil.fn_CurrentDate()),
      /* 주민번호 */
      custRrnId: this.getObjValue(el, 'knb'),
      /* 국적 */
      cusDtlTypCd: this.getObjValue(el, 'zzcusDtlTypCd'),
      /* 고객 ID */
      partnerId: this.getObjValue(el, 'partnerId'),
      /* 고객 채널 ID */
      agtFileId: this.getObjValue(el, 'zaAgtFileId'),
      /* 지급률 */
      shareVl: this.getObjValue(el, 'shareVl'),
      /* 자녀일련번호 */
      chldSnoVl: this.getObjValue(el, 'zzchldSnoVl'),
      /* 수익자구분명 */
      bnfrRolNM: this.fn_GridBnfTypNm(this.getObjValue(el, 'zzbnfrRolCd')),
      /* 수익자구분코드 */
      bnfrRolCd: this.getObjValue(el, 'zzbnfrRolCd'),
      /* 수익자역할 */
      bnfrSno: this.getObjValue(el, 'zaBnfrSno')
    })
  }) // end forEach el
  return acData
}
/************************************************************************************************
 * Function명  : fn_CustInfoList
 * 설명        : 계약/피보험자 정보 추출
 ************************************************************************************************/
PSGAFUtil.fn_CustInfoList = function (event) {
  // let acData = {}
  let reData = {}
  reData.cnsltNo = event.cnsltNo // 컨설턴트 번호
  reData.chnlCstgrId = event.chnlCstgrId // 채널 고객그룹ID
  reData.chnlCustId = event.chnlCustId // 채널 고객 ID (BP)
  reData.cnsltNo = event.cnsltNo // 고객ID
  reData.custNm = event.custNm // 채널 고객 명
  reData.chnlCustScCd = event.chnlCustScCd // 채널고객구분코드
  reData.knb = event.knb // 주민번호
  reData.ralDob = event.ralDob // 실제 생년월일
  reData.mnCustRltnCd = event.mnCustRltnCd // 주고객관계코드
  reData.jobCd = event.jobCd // 직업코드
  reData.jobCdNm = event.jobCdNm // 직업명

  reData.mktPrcusCnsntYn = event.mktPrcusCnsntYn // 마케팅활용동의여부
  reData.mndtCnstgCnsntYn = event.mndtCnstgCnsntYn // 필수컨설팅동의여부
  reData.phclPermYn = event.phclPermYn // 통화허용여부
  reData.smsReciCnsntYn = event.smsReciCnsntYn // SMS수신동의여부
  reData.emailReciCnsntYn = event.emailReciCnsntYn // 이메일수신동의여부

  reData.hbyCd = event.hbyCd // 취미코드
  reData.natyCd = event.natyCd // 국적코드
  reData.dmclScCd = event.dmclScCd // 거주지구분코드
  reData.celno = event.celno // 휴대폰번호
  reData.emailAddr = event.emailAddr // 이메일주소

  reData.mailRcpplScCd = event.mailRcpplScCd // 우편물수령처구분코드 (2: 자택, 3: 직장)
  // 자택 주소
  reData.homFrnoPstcd = event.homFrnoPstcd // 자택앞자리우편번호
  reData.homAddr = event.homAddr // 자택주소
  reData.bldgNo01 = event.bldgNo01 // 자택건물번호
  reData.homDtlad = event.homDtlad // 자택상세주소
  reData.adtnAddr01 = event.adtnAddr01 // 자택부가주소
  reData.homAddrScCd = event.homAddrScCd // 자택주소구분코드 (1:지번주소, 2:도로명주소,3:국외주소)
  reData.homAddrRefnCd = event.homAddrRefnCd // 자택주소정제코드 (01: 정제주소, 02:입력주소)
  reData.homAddrStatCd = event.homAddrStatCd // 자택주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
  reData.homTelno = event.homTelno // 자택전화번호
  // 직장 주소
  reData.jobpFrnoPstcd = event.jobpFrnoPstcd // 직장앞자리우편번호
  reData.jobpAddr = event.jobpAddr // 직장주소
  reData.bldgNo02 = event.bldgNo02 // 직장건물번호
  reData.jobpDtlad = event.jobpDtlad // 직장상세주소
  reData.adtnAddr02 = event.adtnAddr02 // 직장부가주소
  reData.jobpAddrScCd = event.jobpAddrScCd // 직장주소구분코드 (1:지번주소, 2:도로명주소,3:국외주소)
  reData.jobpAddrRefnCd = event.jobpAddrRefnCd // 직장주소정제코드 (01: 정제주소, 02:입력주소)
  reData.jobpAddrStatCd = event.jobpAddrStatCd // 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
  reData.jobpTelno = event.jobpTelno // 직장전화번호
  return reData
}
/************************************************************************************************
 * Function명  : fn_bnfrRolNm
 * 설명        : 수익자 역활명
 * 수익자역할코드 11 계약자
 * 수익자역할코드 31 만기수익자
 * 수익자역할코드 32 분할수익자
 * 수익자역할코드 33 연금수익자
 * 수익자역할코드 34 상해수익자
 * 수익자역할코드 35 사망수익자
 * 수익자역할코드 36 공익특약수익자
 * 수익자역할코드 39 연금후수익자
 ************************************************************************************************/
PSGAFUtil.fn_bnfrRolNm = function (rd_covcptypeCd, map_covcptypeCd) {
  if (!rd_covcptypeCd || rd_covcptypeCd === '') {
    return null
  } // end if

  if ('00001'.indexOf(rd_covcptypeCd) >= 0) {
    // if('00000,00001,00003'.indexOf(rd_covcptypeCd) >= 0){ //임시로 무조건 사망수익자 하도록 수정
    return '35' // 사망
  } else if ('00000'.indexOf(rd_covcptypeCd) >= 0) {
    return '34' // 상해
  } else if ('00102'.indexOf(rd_covcptypeCd) >= 0) {
    return '37' // 상해시 지정청구대리인
  } else if ('01001'.indexOf(rd_covcptypeCd) >= 0) {
    return '31' // 만기
  } else if ('01003'.indexOf(rd_covcptypeCd) >= 0) {
    return '32' // 분할
  } else if ('01002'.indexOf(rd_covcptypeCd) >= 0) {
    if (map_covcptypeCd.hasOwnProperty(rd_covcptypeCd) && map_covcptypeCd[rd_covcptypeCd] === '1') {
      return '39'
    } else {
      return '33'
    } // end else if
  } // end else if

  return ''
}

/************************************************************************************************
 * Function명  : fn_GridBnfTypNm
 * 설명        : 수익자 구분
 ************************************************************************************************/
PSGAFUtil.fn_GridBnfTypNm = function (bnfTyp) {
  if (bnfTyp === '31') {
    return '만기수익자'
  } else if (bnfTyp === '32') {
    return '분할수익자'
  } else if (bnfTyp === '33') {
    return '연금수익자'
  } else if (bnfTyp === '34') {
    return '상해수익자'
  } else if (bnfTyp === '35') {
    return '사망수익자'
  } else if (bnfTyp === '37') {
    return '지정청구대리인'
  } else if (bnfTyp === '39') {
    return '연금후수익자'
  } // end else if

  return null
}
/************************************************************************************************
 * Function명  : fn_MakeRltnrText
 * 설명        : 관계자명 구하기
 ************************************************************************************************/
PSGAFUtil.fn_MakeRltnrText = function (rltnrType, chldSno) {
  let out = ''
  if (rltnrType === '21') {
    out = '주피'
  } else if (rltnrType === '23') {
    out = '종피'
  } else if (rltnrType === '24') {
    out = '자녀' + chldSno
  } else if (rltnrType === '11') {
    out = '계약자'
  } // end else if

  return out
}
/************************************************************************************************
 * Function명  : fn_CustRltnNm
 * 설명        : 주고객관계코드명
 ************************************************************************************************/
PSGAFUtil.fn_CustRltnNm = function (custRltnCd) {
  let out = ''
  if (custRltnCd === '01') {
    out = '본인'
  } else if (custRltnCd === '02') {
    out = '배우자'
  } else if (custRltnCd === '03') {
    out = '자녀'
  } else if (custRltnCd === '04') {
    out = '손자'
  } // end else if

  return out
}
/************************************************************************************************
 * Function명  : fn_PIPlanInfo
 * 설명        : 상품정보
 ************************************************************************************************/
PSGAFUtil.fn_PIPlanInfo = function (resData) {
  let acData = []
  let itemData = {}
  let abdapolicy = resData.pmodel.abdapolicy[0] // this.gaf.getPObj('abdapolicy')[0]
  let abdapolpr = abdapolicy.abdapolpr[0]
  let polprAbdaprem = abdapolpr.polprAbdaprem[0]
  let ztpqlCboextAddmAbdapolpr = abdapolpr.ztpqlCboextAddmAbdapolpr
  let ztpqlCboextAddmAbdacov = abdapolpr.abdacovpac[0].abdacov[0].ztpqlCboextAddmAbdacov

  itemData.aftRctmHopYn = ztpqlCboextAddmAbdapolpr.zaAftRctmHopYn // 후입금 여부
  itemData.prcdId = abdapolpr.zzprcdId // 상품코드
  itemData.productVersionNo = abdapolicy.productVersionNo // 상품버전
  itemData.ilstIrtTypCd = abdapolpr.aldapolpr.zzilsITCLVl // 예시이율유형코드
  itemData.vainsUnQulfYn = itemData.ilstIrtTypCd === '04' ? 'Y' : 'N' // 변액상품여부
  itemData.productBaseId = abdapolpr.productBaseId //
  itemData.prmtrPrdLCd = abdapolpr.aldapolpr.zzprmtrPrdLCd // 상품종목코드
  itemData.prmtrPrdTCd = abdapolpr.aldapolpr.zzprmtrPrdTCd // 파라미터상품유형코드
  itemData.rmvlPrcdCd = abdapolpr.aldapolpr.zzrmvlPrcdCd
  itemData.externalnumId = itemData.prcdId + '0' + itemData.prmtrPrdLCd // 외부번호(상품코드+상품파라메터코드)
  itemData.insrNm = ztpqlCboextAddmAbdacov.zaInsrNm // 보험명
  itemData.planId = ztpqlCboextAddmAbdacov.zaPlanId // 설계,영수증 ID
  itemData.smtotPrm = this.getObjectValue(polprAbdaprem, 'premaftertaxAm') // 합계보험료
  itemData.payfrqCd = polprAbdaprem.payfrqCd // 납입주기코드 00 일시납, 01 연납, 12  월납, 04 분기납
  itemData.jdgYmd = DateUtil.fn_CurrentDate() // 전산심사일오늘날짜 추후변경
  itemData.dignYmd = '' // 건강진단일
  // if (this.gaf.getTObj('rltnrInfo')[0] !== null && undefined !== this.gaf.getTObj('rltnrInfo')[0].zaDignYmd) {
  if (resData.tmodel.rltnrInfo[0] !== null && undefined !== resData.tmodel.rltnrInfo[0].zaDignYmd) {
    itemData.dignYmd = resData.tmodel.rltnrInfo[0].zaDignYmd // 건강진단일
  } // end if

  let cundwInfoDto = resData.tmodel.cundwInfoDto // this.gaf.getTObj('cundwInfoDto') // 전산심사정보
  let systmUndwRslt = resData.tmodel.systmUndwRslt // this.gaf.getTObj('systmUndwRslt') // SYSTEM U/W 결과
  let cundwRsltCdNm = ''
  if (systmUndwRslt != null && systmUndwRslt.zaLstNrmYn === 'N') {
    cundwRsltCdNm = '발행불가'
  } else {
    if (cundwInfoDto != null && cundwInfoDto.zaLstCundwRsltCd === '1') {
      cundwRsltCdNm = '정상'
    } else {
      cundwRsltCdNm = '비정상'
    } // end else if
  } // end else if
  itemData.cundwRsltCdNm = cundwRsltCdNm // 전산심사결과
  itemData.prpadDiscAmt = '0'// 선납할인
  itemData.prpadPrm = '0'// 선납보험료
  itemData.realPadAmt = '0'// 실입금액

  let prpadNts = abdapolpr.aldapolpr.zzfrtmPrpaNVl ? abdapolpr.aldapolpr.zzfrtmPrpaNVl : '0'
  let prpadPrem = 0
  if (Number(prpadNts) > 0) {
    prpadPrem = Number(polprAbdaprem.premaftertaxAm) * Number(prpadNts)
    itemData.prpadPrm = prpadPrem
  } // end if

  // 할인금액 (합계보험료 + 선납보험료 - 실납입보험료)
  // 실납입보험료
  let rlpadPrm = polprAbdaprem.polprAldaprem.zzrlpadPrmAm ? polprAbdaprem.polprAldaprem.zzrlpadPrmAm : '0'
  if (abdapolpr.aldapolpr && abdapolpr.aldapolpr.zzfrtRpdPrmAm) { // 초회실납입보험료 있으면 이 값으로 셋팅
    rlpadPrm = abdapolpr.aldapolpr.zzfrtRpdPrmAm
  } // end if

  if (Number(prpadNts) > 0) {
    let discAmtText = Number(polprAbdaprem.premaftertaxAm) + Number(prpadPrem) - Number(rlpadPrm)
    itemData.prpadDiscAmt = discAmtText
  } // end if

  // ASR201100589_외화보험일경우 원화로 환산(소수점버림)_20201117.PJO
  // let currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId // 외화보험 통화단위
  let currencyId = abdapolicy.currencyId == null || abdapolicy.currencyId === undefined ? 'KRW' : abdapolicy.currencyId
  let smtotPrmTmp = itemData.smtotPrm

  console.log('### PSGAFUtil.fn_PIPlanInfo.itemData.smtotPrm_최초: ' + smtotPrmTmp)
  if (currencyId === 'USD') {
    if ( resData.tmodel.zCurrConvDto.exrtRate !== null && resData.tmodel.zCurrConvDto.exrtRate !== undefined ) {
      // this.smtotPrmKrw = this.smtotPrm * Gaf.getTObj('zCurrConvDto').exrtRate
      console.log('### PSGAFUtil.fn_PIPlanInfo.exrtRate: ' + resData.tmodel.zCurrConvDto.exrtRate)
      console.log('### PSGAFUtil.fn_PIPlanInfo.itemData.smtotPrm_환율*보험료:' + ( itemData.smtotPrm * resData.tmodel.zCurrConvDto.exrtRate ))
      console.log('### PSGAFUtil.fn_PIPlanInfo.itemData.smtotPrm_환율*보험료.버림:' + Math.floor( itemData.smtotPrm * resData.tmodel.zCurrConvDto.exrtRate ))
      smtotPrmTmp = Math.floor( itemData.smtotPrm * resData.tmodel.zCurrConvDto.exrtRate) // 소수점버림
    }
  }
  console.log('### PSGAFUtil.fn_PIPlanInfo.currencyId: ' + currencyId)
  console.log('### PSGAFUtil.fn_PIPlanInfo.smtotPrmTmp_비교값 : ' + smtotPrmTmp )

  /*
  외국인 신분증 진위확인 대상 : 신분증 진위확인 대상인데, 외국인인 경우 불가 처리
  신분증 진위확인 조건 : 월납 25만, 분기납 75만, 연납 300만, 일시납 500만 이상 의 경우
  */
  itemData.idcTofCnfObjYn = '' // 신분증진위 여부
  // itemData.smtotPrm -> smtotPrmTmp
  if (itemData.payfrqCd === '00' && smtotPrmTmp >= 5000000) { // 일시납 500만
    itemData.idcTofCnfObjYn = 'X'
  } else if (itemData.payfrqCd === '01' && smtotPrmTmp >= 3000000) { // 연납 300만
    itemData.idcTofCnfObjYn = 'X'
  } else if (itemData.payfrqCd === '04' && smtotPrmTmp >= 750000) { // 분기납 75만
    itemData.idcTofCnfObjYn = 'X'
  } else if (itemData.payfrqCd === '12' && smtotPrmTmp >= 250000) { // 월납 25만
    itemData.idcTofCnfObjYn = 'X'
  } else {
    itemData.idcTofCnfObjYn = ' '
  } // end else if
  //  asis
  // if (itemData.payfrqCd === '00' && itemData.smtotPrm >= 5000000) { // 일시납 500만
  //   itemData.idcTofCnfObjYn = 'X'
  // } else if (itemData.payfrqCd === '01' && itemData.smtotPrm >= 3000000) { // 연납 300만
  //   itemData.idcTofCnfObjYn = 'X'
  // } else if (itemData.payfrqCd === '04' && itemData.smtotPrm >= 750000) { // 분기납 75만
  //   itemData.idcTofCnfObjYn = 'X'
  // } else if (itemData.payfrqCd === '12' && itemData.smtotPrm >= 250000) { // 월납 25만
  //   itemData.idcTofCnfObjYn = 'X'
  // } else {
  //   itemData.idcTofCnfObjYn = ' '
  // } // end else if
  acData.push(itemData)
  return acData
}
/************************************************************************************************
 * Function명  : isObjectEmpty
 * 설명        : Object 타입의 값 가지고 오기
 * @param val : 값
 * @param key : Value Key
 * @param op  : 빈값 체크
 * @param nullValue  : 디폴트 리턴 값
 * @return * : 빈값 일때 nullValue저장 된 값, 값이 있을때 해당 Type으로 리턴
 ************************************************************************************************/
PSGAFUtil.getObjValue = function (val, key, op = true, nullValue = '') {
  try {
    if (val == null || !val.hasOwnProperty(key)) {
      return nullValue
    } // end if

    if (op && this.isEmptyBase(val[key])) {
      return nullValue
    } // end if
  } catch (error) {
    return nullValue
  } // end try

  return val[key]
}

/************************************************************************************************
 * Function명  : isObjectEmpty
 * 설명        : Object 타입의 빈값 체크
 * @param val : 값
 * @param key : Value Key
 * @param op  : 빈값 체크
 * @return Boolean : 빈값 일때 참, 값이 있을때 거짓
 ************************************************************************************************/
PSGAFUtil.isObjectEmpty = function (val, key, op = false) {
  if (this.getObjectValue(val, key, op, null) === null) {
    return true
  } // end if

  return false
}
/************************************************************************************************
 * Function명  : isObjectEmpty
 * 설명        : Object 타입의 값 가지고 오기
 * @param val : 값
 * @param key : Value Key
 * @param op  : 빈값 체크
 * @param nullValue  : 디폴트 리턴 값
 * @return * : 빈값 일때 nullValue저장 된 값, 값이 있을때 해당 Type으로 리턴
 ************************************************************************************************/
PSGAFUtil.getObjectValue = function (val, key, op = true, nullValue = '') {
  try {
    if (val === null || val === undefined || !val.hasOwnProperty(key)) {
      return nullValue
    } // end if
    if (op && this.isEmptyBase(val[key])) {
      return nullValue
    } // end if
  } catch (error) {
    return nullValue
  } // end try

  return val[key]
}

/************************************************************************************************
 * Function명  : isEmptyBase
 * 설명        :각 타입 별로 빈값 체크 로직
 * @param val : 값
 * @return Boolean : 빈값 일때 참, 값이 있을때 거짓
 ************************************************************************************************/
PSGAFUtil.isEmptyBase = function (val) {
  if (val == null) { return true } // end if
  try {
    if (typeof val === 'string') { // 문자
      if (!val) {
        return true
      } // end else if
    } else if (typeof val === 'number') { // 숫자
      if (!isNaN(Number(String(val))) && parseFloat(String(val)) === 0) { // 숫자
        return true
      } // end if
    } else if (typeof val === 'object') {
      if (Array.isArray(val)) { // Array
        if (val.length > 0) {
          return false
        } else { return true } // end else if
      } else { // Object
        for (var key in val) {
          if (!key) {
            return false
          } // end if
        } // end for
        return true
      } // end else if
    } // end else if
  } catch (error) {
    return true
  } // end try

  return false
}
export default PSGAFUtil
// eslint-disable-next-line

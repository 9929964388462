/**
 * <PRE>
 * System Name : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name : PSXmlConvertUtil.js
 * Description :
 *              AS-IS 파일 XMLConvert.js 대응
 *              서식입력데이터 Object 로 변경 하는 유틸
 * </PRE>
 */
 
const convert = require('xml-js')

const PSXmlConvertUtil = {}
/************************************************************************************************
 * Function명  : xml2json
 * 설명        : xml Data를 Json으로 변경
 * @param xmlData : XML Data
 ************************************************************************************************/
PSXmlConvertUtil.xml2json = function (xmlData) {
  return convert.xml2json(xmlData, {compact: true, spaces: 4})
}

/************************************************************************************************
 * Function명  : xml2Object
 * 설명        : xml Data를 Object 변경
 * @param xmlData : XML Data
 ************************************************************************************************/
PSXmlConvertUtil.xml2Object = function (xmlData) {
  let xml2json = convert.xml2json(xmlData, {compact: true, spaces: 4})
  return JSON.parse(xml2json)
}

export default PSXmlConvertUtil
